import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import Content from '#components/page/Content';
import Main from '#components/page/Main';
import PageContainer from '#components/page/PageContainer';
import PageTitle from '#components/page/PageTitle';

import AddressDetails from '#slices/AddressDetails';

import useLogin from '#hooks/useLogin';

import { listRecords } from '#mrktbox/utils';
import { Address } from '#mrktbox/types';
import useConfig from '#hooks/useConfig';

function ProfilePage() {
  const { customer } = useLogin();
  const { theme } = useConfig()

  const [addresses, setAddresses] = useState<Address[] | null>([]);

  useEffect(() => {
    if (customer?.addresses) {
      const authAddresses = listRecords(customer.addresses);

      setAddresses(
        authAddresses.map((address) => ({
          id: address.id,
          street: address.street,
          city: address.city,
          postal: address.postal,
          description: address.description,
        }))
      );
    }
  }, [customer]);

  return (
    <>
      <Helmet>
        <title>{(`Your Addresses`)}</title>
      </Helmet>
      <Content>
        <Main bgColour='black'>
          <PageContainer style={{ maxWidth: '72rem' }}>
            <PageTitle
              textColor='white'
              title="Your Addresses"
              subtitle="Below are all of the addresses from which you've ordered in the past. To add a new address, start a new order and enter a new address."
            >
            </PageTitle>
            { addresses && addresses?.length>0 && customer &&
              <AddressDetails
                addresses={addresses}
                customer={customer}
              />
            }
          </PageContainer>
        </Main>
      </Content>
    </>
  );
}

export default ProfilePage;
