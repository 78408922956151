import { isMobile } from 'react-device-detect';
import styled from '@emotion/styled';

import { Theme } from '#types';

import useConfig from '#hooks/useConfig';

import BackgroundImage from '#materials/BackgroundImage';

interface PageHeroViewStyle {
  theme : Theme;
  h? : string;
  isMobile : boolean;
}

const PageHeroView = styled.div<PageHeroViewStyle>`
  label: PageHero;
  flex-grow: 1;
  display: flex;
  min-height: 64rem;
  height: 100vh;
  ${(props) =>
    props.isMobile ? `height: 100%; height: -webkit-fill-available;` : ''}
  ${(props) =>
    props.h ? `height: ${props.h}; min-height: ${props.h};` : ''}
`;

interface PageHeroProps {
  imageUrl? : string;
  height? : string;
  children? : React.ReactNode;
}

function PageHero({
  imageUrl,
  height,
  children,
} : PageHeroProps) {
  const { theme } = useConfig();

  const hideHero = !imageUrl;

  if (hideHero) return null;

  return (
    <PageHeroView theme={theme} h={height} isMobile={isMobile}>
      <BackgroundImage imageUrl={imageUrl} bgColour={theme.bgColours.dark} left>
        {children}
      </BackgroundImage>
    </PageHeroView>
  );
}

export default PageHero;
