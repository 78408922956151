import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { isMobile } from 'react-device-detect';
import styled from '@emotion/styled';

import { Product, LineItem, isProductLoaded } from '#mrktbox/types';
import { useNavigation, useProducts } from '#mrktbox';
import { listRecords } from '#mrktbox/utils';

import { Theme } from '#types';

import useConfig from '#hooks/useConfig';
import useCatalogue from '#hooks/useCatalogue';
import useRequests from '#hooks/useRequests';

import Content from '#components/page/Content';
import Main from '#components/page/Main';
import ProductItem from '#components/products/ProductItem';
import ScreenreaderTitle from '#components/page/ScreenreaderTitle';
import ProductImage from '#components/products/ProductImage';

interface Style {
  theme? : Theme;
}

const ProductPageView = styled.div<Style>`
  label: ItemPageView;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    flex-direction: column;
  }
`;

const ProductPageImage = styled.div<Style>`
  flex: 1 1 auto;
  height: 100%;
  display: flex;
  background-color: ${(props) => props.theme.bgColours.tertiary};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    display: none;
  }
`;

const ProductPageContent = styled.div<Style>`
  flex: 0 0 ${(props) => props.theme.item.desktop.maxWidth};
  width: ${(props) => props.theme.item.desktop.maxWidth};
  height: 100%;
  overflow: hidden;
  background-color: ${(props) => props.theme.bgColours.primary};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    width: 100%;
    flex: 1 1 auto;
  }
`;

interface ProductInfoProps {
  productId : number | string;
  lineItemId? : number;
}

function ProductInfo({ productId, lineItemId } : ProductInfoProps) {
  const { navigate } = useNavigation();
  const { brand } = useConfig();
  const { refreshProduct } = useProducts();
  const { allProducts : products, getProductSlug } = useCatalogue();
  const { currentOrder } = useRequests();

  const [loaded, setLoaded] = useState(false);
  const [itemInit, setItemInit] = useState(false);
  const [itemTimeout, setItemTimeout] = useState(false);

  const match = useCallback(() => {
    const p = typeof productId === 'number'
      ? (products?.[productId] ?? null)
      : (listRecords(products).find((p) => productId === getProductSlug(p))
        ?? null)

    if (loaded || !p?.id) return p;
    const id = p.id;

    if (!isProductLoaded(p)) refreshProduct(id);
    setLoaded(true);
    return p;
  }, [productId, loaded, products, refreshProduct, getProductSlug]);

  const [product, setProduct] = useState<Product | null>(match());
  const [lineItem, setLineItem] = useState<LineItem | null>(
    lineItemId ? (currentOrder?.lineItems[lineItemId] ?? null) : null,
  );
  const [quantity, setQuantity] = useState<number>(
    lineItem ? lineItem.quantity : 1,
  );

  useEffect(() => { setProduct(match()) }, [match]);
  useEffect(() => setLineItem(
    lineItemId ? (currentOrder?.lineItems[lineItemId] ?? null) : null,
  ), [currentOrder, lineItemId]);

  useEffect(() => {
    if (!lineItemId) {
      setItemTimeout(false);
      setItemInit(false);
      return;
    }

    if (!itemInit) {
      setTimeout(() => setItemTimeout(true), 2000);
      setItemInit(true);
    }
    if (itemTimeout && lineItemId && !lineItem) navigate('/');
  }, [lineItem, lineItemId, itemInit, itemTimeout, navigate]);

  const itemLoading = !product || (lineItemId && !lineItem);

  return (
    <>
      <Helmet>
        <title>Menu - { product ? product.name : brand.title }</title>
      </Helmet>
      <Content hasFooter={false}>
        <Main bgColour='light' fullHeight padTop={!isMobile}>
          { product &&
            <ScreenreaderTitle>{ product.name }</ScreenreaderTitle>
          }
          <ProductPageView>
            <ProductPageImage>
              <ProductImage product={product} />
            </ProductPageImage>
            <ProductPageContent>
              { !itemLoading &&
                <ProductItem
                  product={product}
                  quantity={quantity}
                  setQuantity={setQuantity}
                  lineItem={lineItem}
                />
              }
            </ProductPageContent>
          </ProductPageView>
        </Main>
      </Content>
    </>
  )
}

export default ProductInfo;
