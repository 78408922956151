import React from 'react';

import { Address } from '#mrktbox/types';
import { Link } from '@mrktbox/clerk';

interface OrderAddressProps {
  address : Address;
  isCard? : boolean;
  children? : React.ReactNode;
}

function OrderAddress({
  address,
  isCard,
  children,
} : OrderAddressProps) {

  return (
    <>
      <Link to='/locations'>
        <p className={isCard ? 'title' : ''}>{ address.description }</p>
        <p>{ address.city }, { address.postal }</p>
        { children }
      </Link>
    </>
  );
}

export default OrderAddress;
