import React from 'react';
import styled from '@emotion/styled';

import { Theme } from '#types';

interface Style { theme? : Theme; }

const SidebarFooterView = styled.div<Style & { bgColour? : 'white' }>`
  flex-shrink: 0;
  width: 100%;
  height: fit-content;
  background-color: ${(props) => props.bgColour ? props.theme.colours.light : props.theme.colours.primary};
`;

const SidebarButtons = styled.div<Style>`
  width: calc(100% - 2rem);
  height: fit-content;
  margin : 0 auto 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;

  button, a {
    width: 100%;
    height: 5rem;
    padding: 0;
  }
`;

interface SidebarFooterProps {
  children? : React.ReactNode;
}

function SidebarFooter({ children } : SidebarFooterProps) {
  return (
    <SidebarFooterView bgColour='white'>
      <SidebarButtons>
        { children }
      </SidebarButtons>
    </SidebarFooterView>
  );
}

export default SidebarFooter;
