import React from 'react';
import styled from '@emotion/styled';

import { Product } from '#mrktbox/types';

import { Theme } from '#types';
import useCatalogue from '#hooks/useCatalogue';

import { LoadedImage } from '#materials/BackgroundImage';

interface Style { theme? : Theme; }
interface ViewStyle extends Style { fade? : boolean; }

const BackgroundImageView = styled('div')<ViewStyle>`
  position: relative;
  height: 100%;
  flex-grow: 1;
  background-color: ${(props) => props.theme.bgColours.light};
  opacity: ${(props) => props.fade ? 0.5 : 1};
`;

const BackgroundImageImage = styled(LoadedImage)`
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 1;
  animation: fade-in 0.25s ease-in-out 0s forwards;
`;

interface BackgroundImageProps {
  product : Product | null;
  fade? : boolean;
  children? : React.ReactNode;
}

function BackgroundImage({ product, fade, children } : BackgroundImageProps) {
  const { isProductLoaded, getProductImage } = useCatalogue();

  const imageUrl = getProductImage(product);

  return (
    <BackgroundImageView fade={fade}>
      { isProductLoaded(product) && (
        <BackgroundImageImage imageUrl={imageUrl} />
      ) }
      { children }
    </BackgroundImageView>
  )
}

export default BackgroundImage;
