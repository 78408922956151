import styled from '@emotion/styled';

import { Theme } from '#types';

import Preface from '#materials/Preface';
import Headline from '#components/page/Headline';

interface Style { theme? : Theme; }

const SidebarHeaderView = styled.div<Style & { bgColour?: 'white' }>`
  width: 100%;
  padding: 2rem;
  background-color: ${(props) => props.bgColour ? props.theme.colours.light : props.theme.colours.primary};

  p {
    line-height: ${(props) => props.theme.fonts.body.lineHeight};
    font-size: ${(props) => props.theme.fonts.sizes.small};

    span {
      padding: 0;
    }
  }

  p + p {
    margin: 1rem 0 0;
  }

  div {
    margin: 2rem auto 0;

    p {
      color: ${(props) => props.theme.colours.alert};
    }
  }
`;

const SidebarHeaderTitle = styled(Headline)`
  margin: 0 0 1rem -0.1rem;
  font-size: ${(props) => props.theme.fonts.sizes.xBig};
`;

interface SidebarHeaderProps {
  title : string;
  preface? : string;
  children : React.ReactNode;
  bgColour? : 'white';
}

function SidebarHeader({ title, preface, children, bgColour } : SidebarHeaderProps) {
  return (
    <SidebarHeaderView bgColour={bgColour}>
      { preface && (<Preface>{ preface }</Preface>) }
      <SidebarHeaderTitle as="h2">
        { title }
      </SidebarHeaderTitle>
      { children }
    </SidebarHeaderView>
  );
}

export default SidebarHeader;
