import React from 'react';
import { isMobile } from 'react-device-detect';
import styled from '@emotion/styled';

import { Link } from '#mrktbox';

import { Theme } from '#types';

import useConfig from '#hooks/useConfig';

import Container from '#materials/Container';

const HOME = process.env.REACT_AAP_HOME;

interface Style {
  theme : Theme;
}

interface FooterContainerStyle extends Style {
  tall : boolean;
}

const FooterView = styled.footer<Style>`
  position: relative;
  z-index: 1;
  width: 100%;
  color: ${(props) => props.theme.colours.light};
  background-color: ${(props) => props.theme.bgColours.light};
  border-top: 1px solid ${(props) => props.theme.colours.dark};
`

const FooterContainer = styled.div<FooterContainerStyle>`
  height: ${(props) => props.tall ? '32rem' : '28rem'};
  padding: ${(props) => props.theme.layout.margin} 0
    ${(props) => props.theme.layout.padding};
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    // padding: ${(props) => props.theme.layout.padding} 0;
    // align-items: flex-start;
  }
`

const FooterContent = styled.div<Style>`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    justify-content: flex-start;
  }
`

const FooterNav = styled.div<Style>`
  border-left: 1px solid ${(props) => props.theme.colours.blueAccent};
  padding-left: 2rem;
  display: flex;
  flex-direction: column;
`

const FooterLogo = styled.a<Style>`
  display: inline-block;
  height: 3.2rem;
  margin: 0 2rem 1rem 0;
  font-family: ${(props) => props.theme.fonts.batchedCollision.family};
  font-weight: ${(props) => props.theme.fonts.batchedCollision.weight};
  color: ${(props) => props.theme.colours.secondary};
  font-size: 40px;
  text-decoration: none;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    height: 2.2rem;
  }

  img {
    display: inline-block;
    width: auto;
    height: 100%;
    pointer-events: none;
  }
`

const FooterLinks = styled.ul<Style>`
  margin: 2rem 0 0;
  display: flex;
  justify-content: flex-start;
  font-size: ${(props) => props.theme.fonts.sizes.small};
  color: ${(props) => props.theme.colours.secondary};
  font-family: ${(props) => props.theme.fonts.batchedCollision.family};

  li {
    display: block;
    margin: 0 3rem 0 0;
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      margin: 0 2rem 0 0;
    }

    &:last-of-type {
      margin-right: 0;
    }

    a {
      text-decoration: none;
      color: ${(props) => props.theme.colours.secondary};
    }

    a:hover,
    a:active,
    a:focus {
      color: ${(props) => props.theme.links.light.hover};
    }
  }
`

const FooterTerms = styled.nav<Style>`
  margin: 1rem 0 0;
  font-size: ${(props) => props.theme.fonts.sizes.xSmall};
  color: ${(props) => props.theme.colours.secondary};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: 1rem 0 2rem;
  }

  ul li {
    float: left;
    margin: 0;
    &:last-child {
      margin-right: 0;
    }

    a {
      text-decoration: none;
      color: ${(props) => props.theme.links.light.colour};
    }

    a:hover,
    a:active,
    a:focus {
      color: ${(props) => props.theme.links.light.hover};
    }
  }
`

const CCorpLogo = styled.div<Style>`
  max-width: 14rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  text-align: right;
  font-size: ${(props) => props.theme.fonts.sizes.xSmall};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    text-align: left;
  }

  img {
    height: 9rem;
    margin-bottom: 1rem;
  }
`

const LogoContainers = styled.div<Style>`
  display: flex;
  flex-direction: column;
  font-family: ${(props) => props.theme.fonts.batchedCollision.family};
  font-weight: ${(props) => props.theme.fonts.batchedCollision.weight};
  font-size: 40px;

  span {
    color: ${(props) => props.theme.colours.dark};
  }
  span a {
    text-decoration: none;
  }
`

const GreenMachineLogo = styled.span<Style>`
  font-family: ${(props) => props.theme.fonts.greenmachineCollision.family};
  letter-spacing: ${(props) => props.theme.letterSpacing.sm};
  a {
    color: ${(props) => props.theme.fonts.greenmachineCollision.colour};
  }
`

const Footer = ({ hasRouter = true }) => {
  const { theme } = useConfig();

  return (
    <FooterView theme={theme} role="contentinfo">
      <Container theme={theme}>
        <FooterContainer theme={theme} tall={isMobile}>
          <FooterContent theme={theme}>
            <FooterNav theme={theme}>
                <LogoContainers theme={theme}>
                  <span>
                    <a
                      target='_blank'
                      rel='noreferrer'
                      href="https://www.batched.ca/"
                    >
                      batched.
                    </a>
                  </span>
                  <span>
                  ×
                  </span>
                  <GreenMachineLogo theme={theme}>
                    <a
                      target='_blank'
                      rel='noreferrer'
                      href="https://www.greenmachinefoodtruck.com/">
                      green machine
                    </a>
                  </GreenMachineLogo>
                </LogoContainers>
              <FooterLinks theme={theme}>
                <li>
                  <a
                    target='_blank'
                    rel='noreferrer'
                    href="https://www.batched.ca/terms-conditions"
                  >
                    Terms & Conditions
                  </a>
                </li>
                <li>
                  <a
                    target='_blank'
                    rel='noreferrer'
                    href="https://www.batched.ca/privacy-policy"
                  >
                    Privacy Policy
                  </a>
                </li>
              </FooterLinks>
            </FooterNav>
          </FooterContent>
        </FooterContainer>
      </Container>
    </FooterView>
  );
}

export default Footer;
