import styled from '@emotion/styled';

import { Theme } from '#types';

import useConfig from '#hooks/useConfig';

interface MainViewStyle {
  theme : Theme;
  bgColour : 'primary' | 'black' | 'light';
  imageUrl? : string;
  fullHeight? : boolean;
  padTop? : boolean;
}

const MainView = styled.main<MainViewStyle>`
  label: Main;

  width: 100%;
  ${(props) => props.fullHeight ? `height: 100%;` : undefined}
  min-height: 100%;
  display: flex;
  flex-direction: column;
  ${(props) => !props.padTop ? `padding-top: 0px;` : undefined}
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: ${(props) => props.theme.bgColours[props.bgColour]};
  padding-top: ${(props) => props.theme.layout.navHeight};
  ${(props) =>
    props.imageUrl
      ? `background-image: url(${props.imageUrl});
    background-color: ${props.theme.bgColours.dark};`
      : undefined}
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding-top: ${(props) => props.theme.layout.navHeightMobile};
  }
`

interface MainProps {
  bgColour? : 'primary' | 'black' | 'light';
  imageUrl? : string;
  fullHeight? : boolean;
  padTop? : boolean;
  children : React.ReactNode;
}

function Main({
  bgColour = 'primary',
  imageUrl,
  fullHeight = false,
  padTop = true,
  children
} : MainProps) {
  const { theme } = useConfig();

  return (
    <MainView
      theme={theme}
      role="main"
      id="main"
      bgColour={bgColour}
      imageUrl={imageUrl}
      fullHeight={fullHeight}
      padTop={padTop}
    >
      { children }
    </MainView>
  )
}

export default Main;
