import React from 'react';
import styled from '@emotion/styled';

import { Product } from '#mrktbox/types';

import { Theme } from '#types';

import Tag from '#materials/Tag';

import ProductImage from '#components/products/ProductImage';

interface Style { theme? : Theme; }

const OrderImageView = styled.div<Style>`
  position: relative;
  width: 4.5rem;
  height: 4.5rem;
  flex-shrink: 0;
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${(props) => props.theme.border.radiusSmall};
  box-shadow: ${(props) => props.theme.boxShadow.outer};
`;

const OrderImageIcon = styled.div<Style>`
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
  z-index: 20;
`;

interface OrderImageProps {
  product : Product,
  icon? : React.ReactNode,
}

const OrderImage = ({ product, icon } : OrderImageProps) => {
  return (
    <OrderImageView>
      { !!icon && (<OrderImageIcon>
        <Tag
          icon={ icon }
          colour="success"
          bgColour="success"
        />
      </OrderImageIcon>) }
      <ProductImage product={product} />
    </OrderImageView>
  );
}

export default OrderImage;
