import React, { useCallback } from 'react';
import styled from '@emotion/styled';

interface CheckBoxStyle { disabled? : boolean; }

const CheckBoxStyled = styled.div<CheckBoxStyle>`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: left;
  flex-direction: row;

  input {
    flex: 0 0 auto;
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  }
`;

interface CheckboxProps {
  id? : string;
  checked : boolean;
  label : React.ReactNode;
  disabled? : boolean;
  onChange : (checked : boolean) => void;
}

function Checkbox({ id, checked, label, disabled, onChange } : CheckboxProps) {
  const handleChange = useCallback(() => {
    if (!disabled) onChange(!checked);
  }, [checked, disabled, onChange]);

  return (
    <CheckBoxStyled disabled={disabled}>
      <input
        id={id}
        type="checkbox"
        checked={checked}
        onChange={handleChange}
      />
      <label htmlFor={id}>
        { label }
      </label>
    </CheckBoxStyled>
  );
}

export default Checkbox;
