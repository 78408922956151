import React, { useCallback, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import styled from '@emotion/styled';

import { Link } from '#mrktbox';

import { Theme } from '#types';

import useConfig from '#hooks/useConfig';
import useModal from '#hooks/useModal';
import useSidebar from '#hooks/useSidebar';
import useRequests from '#hooks/useRequests';
import useCatalogue from '#hooks/useCatalogue';

import Preface from '#materials/Preface';
import Heading from '#materials/Heading';
import BackButton from '#materials/BackButton';
import CartButton from '#materials/CartButton';
import NavButton from '#materials/NavButton';
import { ChevronDown, ChevronUp, Grid, Search } from '#materials/icons';

import Header from '#components/header/Header';
import CategoryDropdown from '#components/catalogue/CategoryDropdown';
import OrderOptionsDropdown from '#components/catalogue/OrderOptionsDropdown';
import OrderTime from '#components/orders/OrderTime';
import Cart from '#components/cart/Cart';
import Nav from '#components/nav/Nav';

interface Style {
  theme? : Theme;
}

interface CategoriesStyle extends Style {
  showCategories?: boolean;
}

const CatalogueHeaderTitleServiceType = styled(Preface)<Style>`
  display: block;
  line-height: 1;
  margin: 0.5rem 0 0;

  color: ${(props) => props.theme.buttons.colours.header.colour};

  font-size: ${(props) => props.theme.fonts.sizes.xSmall};

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.fonts.sizes.xxSmall};
    text-align: left;
  }
`;

const CatalogueHeaderTitleRevenueCenter = styled.div<Style>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.4rem 0 0;

  color: ${(props) => props.theme.buttons.colours.header.colour};

  font-family: MRKTBOX Font;

  > span {
    display: inline-block;
    color: ${(props) => props.theme.buttons.colours.header.colour};
  }
`;

const CatalogueHeaderName = styled.span<Style>`
  max-width: 24rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  span {
    color: ${(props) => props.theme.buttons.colours.header.colour};

    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      font-size: ${(props) => props.theme.fonts.sizes.main};
    }

    @media (min-width: ${(props) => props.theme.breakpoints.tablet}) {
      font-size: ${(props) => props.theme.fonts.sizes.big};
    }
  }
`;

const CatalogueHeading = styled(Heading)<Style>`
  line-height: 1;
  font-family: MRKTBOX Font;
`;

const BatchedPresentsGM = styled.div<Style>`
  display: flex;
  flex-direction: column;

  #batchedHeader {
    font-family: ${(props) => props.theme.fonts.batchedCollision.family};
    font-weight: ${(props) => props.theme.fonts.batchedCollision.weight};
  };

  #greenMachineHeader {
    font-family: ${(props) => props.theme.fonts.greenmachineCollision.family};
    color: ${(props) => props.theme.fonts.greenmachineCollision.colour};
    letter-spacing: ${(props) => props.theme.letterSpacing.sm};
  };

  span:not([id]) {
    font-size: 1.25rem;
  }
`;

const CatalogueHeaderDropdown = styled.span<Style>`
  margin: -0.2rem 0 0 0.2rem;
  width: 1.6rem;
  height: 1.6rem;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 1.2rem;
    height: 1.2rem;
  }
`;

const Categories = styled.button<CategoriesStyle>`
  display: flex;
  font-size: ${(props) => props.theme.fonts.sizes.main};
  font-family: "MRKTBOX Font";
  // color: ${(props) => props.theme.colours.light};
  color: ${(props) => props.showCategories
    ? props.theme.bgColours.green
    : props.theme.colours.light
  };

  height: inherit;
  align-items: center;

  transition: background-color, color .2s ease;
  background-color: ${
    (props) => props.showCategories
      ? props.theme.bgColours.black
      : 'transparent'
  };
  padding-bottom: ${(props) => props.showCategories ? '1px': 'none'};
  margin-bottom: ${(props) => props.showCategories ? '-1px': 'none'};
  margin-left: 1.8rem;
  border-left: ${
    (props) => props.showCategories
      ? '1px solid '+props.theme.border.colour
      : 'none'
  };
  border-right: ${
    (props) => props.showCategories
      ? '1px solid '+props.theme.border.colour
      : 'none'};
  padding: ${(props) => props.showCategories ? '0 .9rem': '0 1rem'};

  :hover {
    color: ${(props) => props.theme.buttons.colours.headerHover.colour};
      background-color: ${(props) =>
        props.theme.buttons.colours.headerHover.bgColour};
      border-color: ${(props) =>
        props.theme.buttons.colours.headerHover.borderColour};
  }

  span {
    padding-top: 0.2rem;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: 0;
    margin-left: 0;
  }
`;

const SearchButton = styled(Link)<Style>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .5rem 2rem;
  border-radius: 2rem;
  border: 2px solid black;

  font-family: MRKTBOX Font;
  font-size: ${(props) => props.theme.fonts.sizes.main};
  text-decoration: none;

  background-color: 'transparent';
  color: ${(props) => props.theme.colours.light};

  svg {
    vertical-align: bottom;
  }

  span {
    padding-top: 0.2rem;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    background-color: transparent;
    color: black;
    border: none;
    padding: 0;
    margin-right: -.3rem;
    font-size: 0;
  }
`;

interface CatalogueHeaderTitleProps {
  showOptions : boolean;
  setShowOptions? : (show : boolean) => void;
  onClick? : () => void;
}

function CatalogueHeaderTitle({
  showOptions,
  setShowOptions,
  onClick,
} : CatalogueHeaderTitleProps) {
  // const { open, openModal } = useModal();
  const { serviceChannel, address, location, time, cutoff } = useRequests();

  const [timeCheck, setTimeCheck] = useState<boolean>(false);
  const [delayed, setDelayed] = useState<boolean>(false);

  // const toggle = useCallback((evt : React.MouseEvent<HTMLButtonElement>) => {
  //   evt.preventDefault();
  //   if (setShowOptions) setShowOptions(!showOptions);
  //   if (onClick) onClick();
  // }, [showOptions, setShowOptions, onClick]);

  useEffect(() => {
    if (timeCheck) return;
    setTimeCheck(true);
    setTimeout(() => { setDelayed(true) }, 2000);
  }, [timeCheck]);

  useEffect(() => {
    if (!delayed) return;
    setDelayed(false);

    if (
      !serviceChannel
        || (!address && !location)
        || (time && (!cutoff || (cutoff > new Date())))
    ) return;
  }, [
    delayed,
    serviceChannel,
    address,
    location,
    time,
    cutoff,
  ]);

  return (
    <>
      <CatalogueHeaderTitleRevenueCenter>
        <CatalogueHeaderName>
          <CatalogueHeading>
            <BatchedPresentsGM>
              <span id='batchedHeader'>batched</span>
              <span>presents</span>
              <span id='greenMachineHeader'>green machine</span>
            </BatchedPresentsGM>
          </CatalogueHeading>
          {/* <CatalogueHeading>
            { (location?.name ?? process.env.REACT_APP_BRAND).toUpperCase() }
          </CatalogueHeading> */}
        </CatalogueHeaderName>
        {/* <CatalogueHeaderDropdown>
          {showOptions ? <ChevronUp /> : <ChevronDown />}
        </CatalogueHeaderDropdown> */}
      </CatalogueHeaderTitleRevenueCenter>
    </>
  );
}

function CatalogueHeader() {
  const { theme } = useConfig();
  const { openSidebar } = useSidebar();
  const { last, setLast } = useCatalogue();
  const { cartCount, resetProposedChanges } = useRequests();

  const [showCategories, setShowCategories] = useState(false);
  const [showOptions, setShowOptions] = useState(false);

  const handleToggleCategories = useCallback(() => {
    setShowCategories(!showCategories);
    setShowOptions(false);
  }, [showCategories, setShowCategories]);

  const handleToggleShowOptions = useCallback(() => {
    if (!showOptions) resetProposedChanges();
    setShowOptions(!showOptions);
    setShowCategories(false);
  }, [showOptions, setShowOptions, resetProposedChanges]);

  const closeAll = useCallback(() => {
    setShowCategories(false);
    setShowOptions(false);
  }, []);

  const handleBack = useCallback(() => {
    setLast('/');
    closeAll();
  }, [setLast, closeAll]);

  const handleCart = useCallback(() => {
    resetProposedChanges();
    openSidebar(<Cart />);
  }, [openSidebar, resetProposedChanges]);

  const handleNav = useCallback(() => {
    openSidebar(<Nav />);
  }, [openSidebar]);

  return (
    <>
      <Header
        style={{ boxShadow: 'none' }}
        title={
          <CatalogueHeaderTitle
            showOptions={showOptions}
            onClick={handleToggleShowOptions}
          />
        }
        borderColour={theme.border.colour}
        left={(
          <>
            <BackButton path={last} onClick={handleBack}/>
            <Categories
              onClick={handleToggleCategories}
              showCategories={showCategories}
            >
              <Grid size={24} />
              <span>&nbsp;Menu</span>
            </Categories>
          </>
        )}
        right={(
          <>
            <SearchButton to="/search" onClick={closeAll}>
              <Search size={isMobile ? 20 : 16} strokeWidth={2} />
              <span>&nbsp;Search</span>
            </SearchButton>
            <CartButton onClick={handleCart} quantity={cartCount} />
            <NavButton onClick={handleNav} />
          </>
        )}
      />
      <CategoryDropdown
        open={showCategories}
        setOpen={setShowCategories}
      />
      <OrderOptionsDropdown
        show={showOptions}
        setShow={setShowOptions}
      />
    </>
  );
}

export default CatalogueHeader;
