import React from 'react';
import styled from '@emotion/styled';

const Overlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  width: 100%;
  height: 100%;
  background-color: #130303;
`;

function LoadingOverlay() {
  return (
    <>
      <Overlay>
        <img src={process.env.REACT_APP_SPLASH_IMAGE} alt="Loading" />
      </Overlay>
    </>
  );
}

export default LoadingOverlay;
