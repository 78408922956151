import React from 'react';
import styled from '@emotion/styled';

import { Product } from '#mrktbox/types';

import { Theme } from '#types';

import ProductImage from '#components/products/ProductImage';

interface Style { theme? : Theme; }

const ProductImageView = styled.div<Style>`
  display: none;
  position: relative;
  z-index: 1;
  width: 100%;
  padding: ${(props) => props.theme.item.desktop.imagePadding};
  padding-top: ${(props) => props.theme.item.desktop.imagePadding};
  padding-bottom: 0;

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    display: block;
    height: ${(props) => props.theme.item.mobile.imageHeight};
    min-height: ${(props) => props.theme.item.mobile.imageMinHeight};
    max-height: ${(props) => props.theme.item.mobile.imageMaxHeight};
    padding: ${(props) => props.theme.item.mobile.imagePadding};
    padding-top: ${(props) => props.theme.item.mobile.imagePadding};
    padding-bottom: 0;
  }
`;

interface ProductItemImageProps {
  product : Product | null;
  imageRef?: React.RefObject<HTMLDivElement>;
}

function ProductItemImage({
  product,
  imageRef,
} : ProductItemImageProps) {

  return (
    <ProductImageView ref={imageRef} >
      <ProductImage product={product} />
    </ProductImageView>
  );
}

export default ProductItemImage;
