import React from 'react';
import styled from '@emotion/styled';

import { Link } from '#mrktbox';

import { Theme } from '#types';

import Heading from '#materials/Heading';
import { ArrowRightLong, ChevronRight } from '#materials/icons';

interface SeeMoreLinkStyle {
  theme? : Theme;
}

const SeeMoreLinkView = styled.div<SeeMoreLinkStyle>`
  flex-grow: 0;

  a {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-decoration: none;
    color: ${(props) => props.theme.links.dark.colour};

    &:hover,
    &:active {
      color: ${(props) => props.theme.links.dark.hover};
      @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        color: ${(props) => props.theme.links.dark.colour};
      }
    }
  }
`;

const SeeMoreLinkText = styled(Heading)<SeeMoreLinkStyle>`
  display: block;
  line-height: 1;
  transition: ${(props) => props.theme.links.transition};
  color: ${(props) => props.theme.fonts.greenmachineCollision.colour};
  font-size: ${(props) => props.theme.fonts.sizes.big};
  font-family: ${(props) => props.theme.fonts.greenmachineCollision.family};
  letter-spacing: ${(props) => props.theme.letterSpacing.sm};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.fonts.sizes.small};
  }

  a:hover &,
  a:active & {
    color: ${(props) => props.theme.links.dark.hover};
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      color: ${(props) => props.theme.links.dark.hover};
    }
  }
`;

const SeeMoreLinkArrow = styled.div<SeeMoreLinkStyle>`
  margin: 0 0 0 1.5rem;
  display: block;
  color: ${(props) => props.theme.fonts.greenmachineCollision.colour};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: none;
  }

  a:hover &,
  a:active & {
    color: ${(props) => props.theme.links.dark.hover};
  }
`;

const SeeMoreLinkChevron = styled.span<SeeMoreLinkStyle>`
  display: none;
  width: 1.4rem;
  height: 1.4rem;
  margin: -0.5rem -0.5rem 0 0;
  color: ${(props) => props.theme.fonts.greenmachineCollision.colour};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: block;
  }

  a:hover &,
  a:active & {
    color: ${(props) => props.theme.links.dark.hover};
  }
`;

interface SeeMoreLinkProps {
  text : string,
  to : string,
}

function SeeMoreLink({ text, to } : SeeMoreLinkProps) {
  return (
    <SeeMoreLinkView>
      <Link to={to}>
        <SeeMoreLinkText>{text}</SeeMoreLinkText>
        <SeeMoreLinkArrow>
          <ArrowRightLong />
        </SeeMoreLinkArrow>
        <SeeMoreLinkChevron>
          <ChevronRight />
        </SeeMoreLinkChevron>
      </Link>
    </SeeMoreLinkView>
  );
}

export default SeeMoreLink
