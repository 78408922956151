import React from 'react';
import { Helmet } from 'react-helmet-async';

import useLogin from '#hooks/useLogin';

import Profile from '#slices/Profile';

import Content from '#components/page/Content';
import Main from '#components/page/Main';
import PageContainer from '#components/page/PageContainer';
import PageTitle from '#components/page/PageTitle';

function ProfilePage() {
  const { customer } = useLogin();

  const contactInfo = customer
    ? Object.values(customer.contactInfo)[0]
    : undefined;

  return (
    <>
      <Helmet>
        <title>{(`Profile`)}</title>
      </Helmet>
      <Content>
        <Main bgColour='light'>
          <PageContainer style={{ maxWidth: '72rem' }}>
            <PageTitle
              title="Profile"
              subtitle="Update your account profile or notification preferences"
            >
            </PageTitle>
            { contactInfo &&
              <Profile contactInfo={contactInfo} />
            }
          </PageContainer>
        </Main>
      </Content>
    </>
  );
}

export default ProfilePage;
