import styled from '@emotion/styled';

import { Theme } from '#types';

const PageContent = styled.div<{ theme? : Theme, textColor? : 'white' | undefined }>`
  opacity: 0;
  animation: slide-up 0.25s ease-in-out 0.25s forwards;
  text-align: center;
  color: ${(props) => props.textColor ?? props.theme.colours.light};
  max-width: ${(props) => props.theme.layout.maxWidth};
  margin: ${(props) => props.theme.layout.padding} auto;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin: ${(props) => props.theme.layout.paddingMobile} auto;
    font-size: ${(props) => props.theme.fonts.sizes.small};
    text-align: left;
  }

  & > p {
    line-height: ${(props) => props.theme.fonts.body.lineHeight};
    margin: 1em 0;
  }
`;

export default PageContent;
