import React, { useCallback, useState } from 'react'
import styled from '@emotion/styled';

import { Customer, ContactInfo, Partial } from '#mrktbox/types';

import { useForm } from '#mrktbox/hooks';

import Form, { FormWrapper } from '#materials/Form';
import FormError from '#materials/FormError';
import ButtonStyled from '#materials/ButtonStyled';
import ButtonLink from '#materials/ButtonLink';
import Checkbox from '#materials/Checkbox';
import Input from '#materials/Input';

export type RegistrationData = Partial<
  Customer
    & ContactInfo
    & { password : string, confirm? : string }
>;

const CheckboxSection = styled.div`
  margin : 1rem 0;
`;

const TERMS_LINK = process.env.REACT_APP_TERMS_LINK || '#';
const PRIVCACY_LINK = process.env.REACT_APP_PRIVACY_LINK || '#';

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

function formatPhone(phone : string) {
  return phone.replace(/\D/g, '');
}

interface RegistrationFormProps {
  onSubmit? : () => Promise<void>;
  error? : string;
}

function RegistrationForm({
  onSubmit = async () => {},
  error='',
} : RegistrationFormProps) {
  const { state, dispatch } = useForm<RegistrationData>();

  const [terms, setTerms] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [phoneValid, setPhoneValid] = useState(true);
  const [emailValid, setEmailValid] = useState(true);
  const [passwordValid, setPasswordValid] = useState(true);
  const [confirmValid, setConfirmValid] = useState(true);

  const handleSubmit = useCallback(async () => {
    if (!state) return;
    setSubmitting(true);
    await onSubmit();
    setSubmitting(false);
  }, [state, onSubmit]);

  const setName = useCallback((name : string) => {
    dispatch({ name });
  }, [dispatch]);
  const setNickname = useCallback((nickname : string) => {
    dispatch({ nickname });
  }, [dispatch]);
  const setPhone = useCallback((phone : string) => {
    if (!phone || formatPhone(phone).length === 10) setPhoneValid(true);
    dispatch({ phone : formatPhone(phone) });
  }, [dispatch]);
  const setEmail = useCallback((email : string) => {
    if (!email || emailRegex.test(email)) setEmailValid(true);
    dispatch({ email });
  }, [dispatch]);
  const setPassword = useCallback((password : string) => {
    if (!password || password.length >= 6) setPasswordValid(true);
    dispatch({ password });
  }, [dispatch]);
  const setEmailNotification = useCallback((emailNotifications : boolean) => {
    dispatch({ emailNotifications });
  }, [dispatch]);
  const setSmsNotification = useCallback((smsNotifications : boolean) => {
    dispatch({ smsNotifications });
  }, [dispatch]);
  const setConfirm = useCallback((confirm : string) => {
    if (!confirm || state?.password === confirm) setConfirmValid(true);
    dispatch({ confirm });
  }, [state, dispatch]);

  const handlePhoneBlur = useCallback(() => {
    setPhoneValid(!state?.phone || formatPhone(state.phone).length === 10);
  }, [state]);
  const handleEmailBlur = useCallback(() => {
    setEmailValid(!state?.email || emailRegex.test(state.email));
  }, [state]);
  const handlePasswordBlur = useCallback(() => {
    setPasswordValid(!state?.password || state?.password.length >= 6);
  }, [state]);
  const handleConfirmBlur = useCallback(() => {
    setConfirmValid(!state?.confirm || state?.password === state?.confirm);
  }, [state]);

  return (
    <FormWrapper>
      <Form
        error={(
          <FormError errMsg={error} />
        )}
        buttons={(
          <ButtonStyled
            type="submit"
            disabled={submitting
              || !state
              || !state.name
              || !state.email
              || !state.password
              || !(emailRegex.test(state.email))
              || state.password.length < 6
              || state.confirm !== state.password
              || terms === false
            }
          >
            Register
          </ButtonStyled>
        )}
        onSubmit={handleSubmit}
      >
        <Input
          label="Name"
          name="name"
          type="text"
          value={state?.name ?? ''}
          onChange={setName}
          required={true}
        />
        <Input
          label="Nickname"
          name="nickname"
          type="text"
          value={state?.nickname ?? ''}
          onChange={setNickname}
          required={false}
        />
        <FormError errMsg={!phoneValid
          ? 'Phone number must be exactly 10 digits'
          : undefined}
        />
        <FormError errMsg={!emailValid ? 'Invalid email' : undefined} />
        <Input
          label="Email"
          name="email"
          type="email"
          value={state?.email ?? ''}
          onChange={setEmail}
          onBlur={handleEmailBlur}
          required={true}
        />
        <FormError errMsg={!passwordValid
          ? 'Password must be at least 6 characters'
          : undefined} />
        <Input
          label="Password"
          name="password"
          type="password"
          value={state?.password ?? ''}
          onChange={setPassword}
          onBlur={handlePasswordBlur}
          required={true}
        />
        <FormError errMsg={!confirmValid
          ? 'Passwords do not match'
          : undefined}
        />
        <Input
          label="Confirm Password"
          name="confirm"
          type="password"
          value={state?.confirm ?? ''}
          onChange={setConfirm}
          onBlur={handleConfirmBlur}
          required={true}
        />
        <CheckboxSection>
          <Checkbox
            checked={state?.emailNotifications ?? false}
            label='Receive Email Notifications'
            onChange={setEmailNotification}
          />
        </CheckboxSection>
        <CheckboxSection>
          <Checkbox
            checked={terms ?? false}
            label={(<span>
              { 'I have read & agree to the ' }
              <ButtonLink href={TERMS_LINK} newTab >
                Terms of Service
              </ButtonLink>
              { ' & ' }
              <ButtonLink href={PRIVCACY_LINK} newTab >
                Privacy Policy
              </ButtonLink>
            </span>)}
            onChange={setTerms}
          />
        </CheckboxSection>
      </Form>
    </FormWrapper>
  )
}

export default RegistrationForm;
