import { useContext } from 'react';

import MapContext from '#context/MapContext';

import { useAddresses } from '#mrktbox';

function useMap() {
  const {
    makeMap,
    showMap,
    openMap,
    closeMap,
    setCenter,
    setCurrentLocation,
    removeCurrentLocation,
    refreshCurrentLocation,
    setPin,
    removePin,
  } = useContext(MapContext);

  const { retrieveAddress, searchAddresses } = useAddresses();

  return {
    makeMap,
    searchAddresses,
    refreshAddress : retrieveAddress,
    showMap,
    openMap,
    closeMap,
    setCenter,
    refreshCurrentLocation,
    setCurrentLocation,
    removeCurrentLocation,
    setPin,
    removePin,
  };
}

export default useMap;
