import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';

import { Product } from '#mrktbox/types';
import { useAdjustments } from '#mrktbox/hooks';

import { Theme } from '#types';
import useCatalogue from '#hooks/useCatalogue';

import Heading from '#materials/Heading';

import ProductPriceCals from '#components/products/ProductPriceCals';

interface Style { theme? : Theme; }
interface HeaderViewStlye extends Style { compact? : boolean; }

const ProductHeaderView = styled.div<Style>`
  background-color: ${(props) => props.theme.bgColours.light};
  // background-color: ${(props) => props.theme.bgColours.primary};
`;

const ProductHeaderBanner = styled.div<HeaderViewStlye>`
  display: ${(props) => props.compact ? 'flex' : 'none'};
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1rem ${(props) => props.theme.item.mobile.padding};
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => props.theme.bgColours.light};
  // background-color: ${(props) => props.theme.bgColours.primary};
  border-bottom: 1px solid ${(props) => props.theme.border.colour};
  animation: fade-in 0.1s ease-in-out 0s forwards;
`;

const ProductHeaderContainer = styled.div`
  flex: 1 1 auto;
  margin: 0 0 2rem;
`;

const ProductInfo = styled.div<HeaderViewStlye>`
  display: flex;
  width: 100%;
  ${(props) => props.compact
    ? 'flex-direction: row;'
    : 'flex-direction: column;'}
  justify-content: space-between;
  transition: ${(props) => props.theme.links.transition};
  padding: ${(props) => props.compact ? 0 : props.theme.item.desktop.padding};
  padding-bottom: 0;

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding: ${(props) => props.compact ? 0 : props.theme.item.mobile.padding};
    padding-bottom: 0;
  }

  &.isCustomize {
    width: 100%;
    display: flex;
    align-items: center;
  }
`;

const ProductNameContainer = styled.div<HeaderViewStlye>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${(props) => props.compact && 'flex: 1 1 auto;'}
`;

const ProductName = styled(Heading)<HeaderViewStlye>`
  display: block;
  margin-left: -0.1rem;
  transition: ${(props) => props.theme.links.transition};
  font-family: ${(props) => props.theme.fonts.greenmachineCollision.family};
  letter-spacing: ${(props) => props.theme.letterSpacing.sm};
  color: ${(props) => props.theme.fonts.greenmachineCollision.colour};

  ${(props) => props.compact && 'line-height: 1'}
`;

const ProductPrice = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  margin: 0 0 0.5rem;
`;

const ProductAdjustment = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;

  opacity: 0.8;
  font-family: 'MRKTBOX Font';

  * {
    white-space: nowrap;
    margin: 0;
  }
`;

interface ProductHeaderProps {
  product : Product;
  selections : { [id : number] : Product[]; }
  compact? : boolean;
  headerRef? : React.RefObject<HTMLDivElement>;
}

function ProductHeader({
  product,
  selections,
  compact,
  headerRef,
} : ProductHeaderProps) {
  const {
    calculateAdjustmentAbsolute,
    calculateAdjustmentRelative,
    getProductAdjustments,
  } = useAdjustments();
  const {
    formatAdjustment,
    calculatePrice,
  } = useCatalogue();

  const getAdjustments = useCallback(() => (
    getProductAdjustments(product)
  ), [getProductAdjustments, product]);

  const [adjustments, setAdjustments] = useState(getAdjustments());

  const getPrice = useCallback(() => (
    calculatePrice({ product, adjustments : [] })
  ), [calculatePrice, product]);
  const getAdjusted = useCallback(() => (
    calculatePrice({ product, adjustments })
  ), [calculatePrice, product, adjustments]);
  const getSelectionPrice = useCallback(() => (
    calculatePrice({ product, selections, relative : true })
  ), [calculatePrice, product, selections]);

  const [price, setPrice] = useState(getPrice());
  const [newPrice, setNewPrice] = useState(getAdjusted());
  const [selectionPrice, setSelectionPrice] = useState(getSelectionPrice());

  useEffect(() => setAdjustments(getAdjustments()), [getAdjustments]);
  useEffect(() => setPrice(getPrice()), [getPrice]);
  useEffect(() => setNewPrice(getAdjusted()), [getAdjusted]);
  useEffect(() => setSelectionPrice(getSelectionPrice()), [getSelectionPrice]);

  const hasAdjustment = price.amount !== newPrice.amount;

  return (
    <ProductHeaderView>
      <ProductHeaderBanner compact={compact} ref={headerRef} >
        <ProductInfo compact >
          <ProductNameContainer compact >
            <ProductName size={"big"} as="p">
              { product.name }
            </ProductName>
          </ProductNameContainer>
          <ProductPriceCals
            price={product.price}
            size="main"
            style={compact ? undefined : { margin: '1rem 0 0' }}
          />
        </ProductInfo>
      </ProductHeaderBanner>
      <ProductHeaderContainer>
        <ProductInfo>
          <ProductNameContainer>
            <ProductName size="xBig" as="p">
              { product.name }
            </ProductName>
          </ProductNameContainer>
          <ProductPrice>
            <ProductPriceCals
              price={price}
              adjustment={hasAdjustment ? undefined : selectionPrice}
              size="main"
              variant={hasAdjustment ? 'strike' : 'default'}
              style={compact ? undefined : { margin: '1rem 0 0' }}
            />
            { hasAdjustment && (
              <ProductPriceCals
                price={newPrice}
                adjustment={selectionPrice}
                size="main"
                variant='deal'
                style={compact ? undefined : { margin: '1rem 0 0' }}
              />
            ) }
          </ProductPrice>
          { adjustments.map((adjustment) => {
            const relative = Math.abs(calculateAdjustmentRelative(
              adjustment,
              product
            )) * 100;
            return (
              <ProductAdjustment>
                <span>{ formatAdjustment(adjustment, product) }</span>
                <ProductPriceCals
                  price={calculateAdjustmentAbsolute(adjustment, product)}
                  size="main"
                  absolute
                  variant='deal'
                />
                { `(${relative % 1 ? relative.toFixed(1) : relative}%)` }
              </ProductAdjustment>
            );
          }) }
        </ProductInfo>
      </ProductHeaderContainer>
    </ProductHeaderView>
  );
}

export default ProductHeader;
