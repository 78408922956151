import React from 'react';
import styled from '@emotion/styled';

import { Product, ProjectedOrder } from '#mrktbox/types';
import { useSubscriptions } from '#mrktbox';

import { Theme } from '#types';

import { Refresh } from '#materials/icons';

import OrderImage from '#components/orders/OrderImage';

interface Style { theme? : Theme; }

const OrderImagesView = styled.div<Style>`
  margin: 1.5rem 0 0;

  p {
    font-size: ${(props) => props.theme.fonts.sizes.xSmall};
    line-height: ${(props) => props.theme.fonts.body.lineHeight};
  }
`;

const OrderImagesList = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: top;
  margin: 0 0 1rem;
  padding: 1rem 0 0;
  overflow: hidden;
`;

interface OrderImageProps {
  products : Product[],
  order? : ProjectedOrder,
}

const OrderImages = ({
  products,
  order,
} : OrderImageProps) => {
  const { findProductSubscription } = useSubscriptions();

  return (
    <OrderImagesView>
      <OrderImagesList>
        { products.map((product, index) => {
          const isSubscription = order
            ? findProductSubscription(product, order)
            : false;
          return (<OrderImage
            key={`${index}`}
            product={product}
            icon={!!isSubscription && <Refresh />}
          />);
        }) }
      </OrderImagesList>
      <p style={{fontFamily: 'Bangers', color: '#209D50', letterSpacing: "1px"}}>{ products.map((i) => i.name).join(', ') }</p>
    </OrderImagesView>
  );
}

export default OrderImages;
