import React, { useCallback } from 'react';
import styled from '@emotion/styled';

import { Product, LineItem } from '#mrktbox/types';
import { useNavigation, useSubscriptions } from '@mrktbox/clerk';
import { formatCurrency, formatDateTime, formats } from '#mrktbox/utils';

import { Theme } from '#types';

import useRequests from '#hooks/useRequests';

import useModal from '#hooks/useModal';
import useCatalogue from '#hooks/useCatalogue';

import ButtonStyled from '#materials/ButtonStyled';
import Heading from '#materials/Heading';
import { Minus, Plus, Refresh } from '#materials/icons';

import SubscriptionChange from '#components/orders/SubscriptionChange';

interface Style {
  theme? : Theme;
}

const ProductFooterView = styled.div<Style>`
  label: ProductItemFooter;
  flex: 0 0 auto;
  position: relative;
  padding: ${(props) => props.theme.item.desktop.padding};
  background-color: ${(props) => props.theme.bgColours.light};
  // background-color: ${(props) => props.theme.bgColours.primary};
  // box-shadow: 0 -15px 30px ${(props) => props.theme.overlay.primary};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
    padding: ${(props) => props.theme.item.mobile.padding};
  }
`;

const ProductFooterDefault = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ProductFooterDefaultCircle = styled.div<Style>`
  flex: 0 0 5rem;
  width: 5rem;
  height: 5rem;
  margin-right: ${(props) => props.theme.item.desktop.padding};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin-right: ${(props) => props.theme.item.mobile.padding};
    flex: 0 0 4rem;
    width: 4rem;
    height: 4rem;
  }

  a {
    width: 100%;
    height: 100%;
    padding: 0;
    border-radius: 2.5rem;
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      border-radius: 2rem;
    }
  }

  button {
    width: 100%;
    height: 100%;
    padding: 0;
    border-radius: 2.5rem;
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      border-radius: 2rem;
    }
  }
`;

const ProductFooterDefaultQuantity = styled.div<Style>`
  min-width: 5rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    min-width: 4rem;
    height: 4rem;
  }
`;

const ProductFooterDefaultCount = styled(Heading)`
  display: block;
`;

const ProductFooterDefaultButton = styled.div<Style>`
  flex: 1 1 auto;

  button {
    width: 100%;
    padding: 0;
    height: 5rem;
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      height: 4rem;
    }
  }
`;

const ProductFooterDefaultPrice = styled.span<Style>`
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: none;
  }

  span {
    padding: 0 0.5rem;
  }
`;

const RefreshIcon = styled.span`
  height: 1.5rem;
  margin-right: 1rem;
`;

interface ProductFooterProps {
  product : Product;
  selections : { [id : number] : Product[]; };
  quantity : number;
  lineItem? : LineItem | null;
  setQuantity : (quantity: number) => void;
  period? : number;
}

function ProductFooter({
  product,
  selections,
  quantity,
  period,
  lineItem,
  setQuantity,
} : ProductFooterProps) {
  const { navigate } = useNavigation();
  const { openModal } = useModal();
  const {
    findLineItemSubscription,
  } = useSubscriptions();
  const {
    last,
    isProductStocked,
    validateSelections,
    calculatePrice,
  } = useCatalogue();
  const {
    currentOrder,
    addItem,
    updateItem,
    isOrderOpen,
    evaluateReccurence,
  } = useRequests();

  const incrementQuantity = useCallback(() => {
    const newQuantity = quantity + 1;
    setQuantity(newQuantity);
  }, [quantity, setQuantity]);

  const decrementQuantity = useCallback(() => {
    const newQuantity = quantity - 1;
    if (newQuantity > 0){
      setQuantity(newQuantity);
    }
  }, [quantity, setQuantity]);

  const handleUpdateSubscription = useCallback(async (
    target : 'this' | 'future',
  ) => {
    if (!currentOrder || !lineItem) return;
    const success = await updateItem(
      lineItem,
      { quantity },
      selections,
      { target }
    );
    if (success) navigate(last);
  }, [
    currentOrder,
    lineItem,
    selections,
    quantity,
    last,
    updateItem,
    navigate,
  ]);

  const handleAddToCart = useCallback(async () => {
    if (lineItem) {
      if (!currentOrder) return;
      const subscription = findLineItemSubscription(lineItem, currentOrder);

      if (!subscription) {
        const success = await updateItem(lineItem, { quantity }, selections);
        if (success) navigate(last);
        return;
      }

      const recurrence = evaluateReccurence(subscription);

      if (!recurrence || (period !== recurrence)) {
        const success = await updateItem(
          lineItem,
          { quantity },
          selections,
          { target : !recurrence ? 'this' : 'future' },
        );
        if (success) navigate(last);
        return;
      }

      openModal(
        <SubscriptionChange
          title="Update subscription, or just this order?"
          prompt={`This is a ${product.name} subscription.\n Would you like to`
            + ' apply changes to this order only'
            + (currentOrder.time
              ? ` (${formatDateTime(currentOrder.time, formats.easy)})`
              : '')
            + ', or all future orders?'}
          selectTarget={handleUpdateSubscription}
        />,
      );
    } else {
      const success = await addItem({ product, selections, quantity, period });
      if (success) navigate(last);
    }
  }, [
    product,
    selections,
    quantity,
    period,
    lineItem,
    handleUpdateSubscription,
    currentOrder,
    addItem,
    updateItem,
    evaluateReccurence,
    last,
    findLineItemSubscription,
    openModal,
    navigate,
  ]);

  const orderOpen = isOrderOpen();
  const outOfStock = !isProductStocked(product);
  const disableProduct = !validateSelections(product, selections)
    || !orderOpen
    || outOfStock;

  return (
    <ProductFooterView>
      <ProductFooterDefault>
        <ProductFooterDefaultCircle style={{ margin: 0 }}>
          <ButtonStyled
            onClick={decrementQuantity}
            disabled={(quantity <= 1) || !orderOpen}
            size="big"
            colour="green"
          >
            <Minus size={25} />
          </ButtonStyled>
        </ProductFooterDefaultCircle>
        <ProductFooterDefaultQuantity>
          <ProductFooterDefaultCount size="xBig">
            { quantity }
          </ProductFooterDefaultCount>
        </ProductFooterDefaultQuantity>
        <ProductFooterDefaultCircle>
          <ButtonStyled
            onClick={incrementQuantity}
            disabled={!orderOpen}
            size="big"
            colour="green"
          >
            <Plus size={25} />
          </ButtonStyled>
        </ProductFooterDefaultCircle>
        <ProductFooterDefaultButton>
          <ButtonStyled
            onClick={handleAddToCart}
            disabled={disableProduct}
            size="big"
            colour='green'
          >
            { !!period && (
              <RefreshIcon>
                <Refresh size={14} />
              </RefreshIcon>
            ) }
            { outOfStock
                ? 'Out of Stock'
                : (lineItem
                  ? 'Update Order'
                  : period ? 'Subscribe' : 'Add to Order')
            }
            { (!lineItem && !outOfStock) && (
              <ProductFooterDefaultPrice>
                <span>&mdash;</span>
                { formatCurrency(calculatePrice({
                  product,
                  selections,
                  quantity,
                })) }
                { !!period && <span>/ Order</span> }
              </ProductFooterDefaultPrice>
            ) }
          </ButtonStyled>
        </ProductFooterDefaultButton>
      </ProductFooterDefault>
    </ProductFooterView>
  );
}

export default ProductFooter;
