import styled from '@emotion/styled';

import { Theme } from '#types';

import Text from '#materials/Text';
import Heading from '#materials/Heading';
import Checkmark from '#materials/Checkmark';
import { PlusCircle, XCircle } from '#materials/icons';

interface Style { theme? : Theme; }
interface ViewStyle extends Style {
  isApplied? : boolean;
}

const CheckoutButtonView = styled.button<ViewStyle>`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0 0;
  border-style: solid;
  border-width: ${(props) => props.theme.buttons.sizes.large.borderWidth};
  border-radius: ${(props) => props.theme.buttons.sizes.large.borderRadius};
  background-color: ${(props) => props.theme.buttons.colours.large.bgColour};
  border-color: ${(props) =>
    props.isApplied
      ? props.theme.colours.success
      : props.theme.buttons.colours.large.borderColour};

  &:hover {
    background-color: ${(props) =>
      props.isApplied || props.disabled
        ? props.theme.buttons.colours.large.bgColour
        : props.theme.bgColours.green};
    border-color: ${(props) =>
      props.isApplied
        ? props.theme.colours.success
        : props.disabled
        ? props.theme.buttons.colours.large.borderColour
        : props.theme.buttons.colours.largeHover.borderColour};
  }
`;

const CheckoutButtonCheckmark = styled.div`
  position: absolute;
  z-index: 2;
  top: -0.8rem;
  right: -0.8rem;
`;

const CheckoutButtonInfo = styled.div<Style>`
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1.5rem 0 1.5rem 1.5rem;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 1rem 0 1rem 1.5rem;
  }
`;

const CheckoutButtonIcon = styled.div<ViewStyle>`
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0 2rem 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.buttons.colours.large.iconColour};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: 0 1.5rem 0 0;
  }

  button:enabled:hover & {
    color: ${(props) =>
      props.isApplied
        ? props.theme.buttons.colours.large.iconColour
        : props.theme.buttons.colours.largeHover.iconColour};
  }
`;

const CheckoutButtonText = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;

  & > span {
    display: block;
    text-align: left;
  }
`;

const CheckoutButtonTitle = styled(Heading)<ViewStyle>`
  transition: ${(props) => props.theme.links.transition};
  font-size: ${(props) => props.theme.fonts.sizes.medium};
  color: ${(props) => props.theme.buttons.colours.large.colour};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.fonts.sizes.small};
  }

  button:enabled:hover & {
    color: ${(props) =>
      props.isApplied
        ? props.theme.buttons.colours.large.colour
        : props.theme.buttons.colours.largeHover.colour};
  }
`;

const CheckoutButtonSubtitle = styled(Text)<ViewStyle>`
  transition: ${(props) => props.theme.links.transition};
  margin: 0.3rem 0 0;
  white-space: nowrap;
  font-size: ${(props) => props.theme.fonts.sizes.small};
  font-family: ${(props) => props.theme.fonts.mono.family};
  color: ${(props) => props.theme.buttons.colours.large.subtitleColor};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.fonts.sizes.xSmall};
  }

  button:enabled:hover & {
    color: ${(props) =>
      props.isApplied
        ? props.theme.buttons.colours.large.subtitleColor
        : props.theme.buttons.colours.largeHover.subtitleColour};
  }
`;

interface ButtonStyle extends Style {
  disabled? : boolean;
}

const CheckoutButtonButton = styled.div<ButtonStyle>`
  flex-grow: 0;
  flex-shrink: 0;
  width: 12rem;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 2rem 0 0;
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 0 1.5rem 0 0;
  }
`;

const CheckoutButtonButtonIcon = styled.div<ViewStyle>`
  width: 1.8rem;
  height: 1.8rem;
  color: ${(props) => props.theme.buttons.colours.large.iconColour};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 1.6rem;
    height: 1.6rem;
  }

  button:enabled:hover & {
    color: ${(props) =>
      props.isApplied
        ? props.theme.colours.error
        : props.theme.buttons.colours.largeHover.iconColour};
  }
`;

const CheckoutButtonApply = styled(Text)`
  display: block;
  line-height: 1;
  margin: 0 0 0 0.5rem;
  transition: ${(props) => props.theme.links.transition};
  color: ${(props) => props.theme.buttons.colours.large.iconColour};
  font-size: ${(props) => props.theme.fonts.sizes.small};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: 0.1 0 0 0.4rem;
    font-size: ${(props) => props.theme.fonts.sizes.xSmall};
  }

  svg {
    transition: ${(props) => props.theme.links.transition};
  }

  button:enabled:hover & {
    color: ${(props) => props.theme.buttons.colours.largeHover.iconColour};
  }
`;

const CheckoutButtonRemove = styled(CheckoutButtonApply)`
  button:enabled:hover & {
    color: ${(props) => props.theme.colours.error};
  }
`;

interface CheckoutButtonProps {
  icon? : React.ReactNode;
  title? : string;
  subtitle? : string;
  finePrint? : string;
  onPress? : () => void;
  isApplied? : boolean;
  disabled? : boolean;
  applyText? : string;
}

function CheckoutButton({
  icon,
  title,
  subtitle,
  finePrint,
  onPress,
  isApplied,
  disabled,
  applyText = 'Apply',
} : CheckoutButtonProps) {
  return (
    <CheckoutButtonView
      onClick={onPress}
      disabled={disabled}
      isApplied={isApplied}
    >
      { isApplied ? (
        <CheckoutButtonCheckmark>
          <Checkmark />
        </CheckoutButtonCheckmark>
      ) : null }
      <CheckoutButtonInfo>
        { icon && (
          <CheckoutButtonIcon isApplied={isApplied}>
            { icon }
          </CheckoutButtonIcon>
        ) }
        <CheckoutButtonText>
          <CheckoutButtonTitle isApplied={isApplied}>
            { title }
          </CheckoutButtonTitle>
          { subtitle && (
            <CheckoutButtonSubtitle isApplied={isApplied}>
              { subtitle }
            </CheckoutButtonSubtitle>
          ) }
          { finePrint && (
            <CheckoutButtonSubtitle isApplied={isApplied}>
              { finePrint }
            </CheckoutButtonSubtitle>
          ) }
        </CheckoutButtonText>
      </CheckoutButtonInfo>
      <CheckoutButtonButton disabled={disabled}>
        { isApplied ? (
          <>
            { disabled ? (
              <CheckoutButtonApply>Applied</CheckoutButtonApply>
            ) : (
              <>
                <CheckoutButtonButtonIcon isApplied={isApplied}>
                  <XCircle />
                </CheckoutButtonButtonIcon>
                <CheckoutButtonRemove>Remove</CheckoutButtonRemove>
              </>
            ) }
          </>
        ) : (
          <>
            <CheckoutButtonButtonIcon>
              <PlusCircle />
            </CheckoutButtonButtonIcon>
            <CheckoutButtonApply>{ applyText }</CheckoutButtonApply>
          </>
        )}
      </CheckoutButtonButton>
    </CheckoutButtonView>
  );
}

export default CheckoutButton;
