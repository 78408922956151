import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled'

import { Category } from '#mrktbox/types';

import { Theme } from '#types';

import useFocus from '#hooks/useFocus';
import useCatalogue from '#hooks/useCatalogue';

import Transition, { TransitionGroup } from '#materials/Transition';
import { ChevronLeftCircle } from '#materials/icons';

import CategoriesList, {
  ListItem
} from '#components/categories/CategoriesList';

interface Style {
  theme? : Theme;
}

interface MenuCategoriesDropdownStyle extends Style {
  show : boolean;
}

const CategoriesDropdownView = styled.div<
  MenuCategoriesDropdownStyle & { bgColor?: 'black'}
>`
  position: fixed;
  max-height: calc(100vh - ${(props) => props.theme.layout.navHeight});
  z-index: 12;
  top: ${(props) => props.theme.layout.navHeight};
  left: 0;
  right: 0;
  padding: 2rem 0 0 0;
  overflow-y: auto;
  transition: all 0.125s ease;
  opacity: ${(props) => (props.show ? 1 : 0)};
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  transform: translateY(${(props) => (props.show ? '0' : '-100%')});
  background-color: ${(props) => props.bgColor === 'black'
    ? props.theme.bgColours.black
    : props.theme.bgColours.primary
  };
  border-top: 1px solid ${(props) => props.theme.colours.light};
  border-bottom: 1px solid ${(props) => props.theme.colours.light};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    top: ${(props) => props.theme.layout.navHeightMobile};
    padding: 0;
  }
`;

const CategoriesDropdownOverlay = styled.div<Style>`
  position: fixed;
  z-index: 11;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${(props) => props.theme.overlay.dark};
`;

interface CategoriesDropdownProps {
  open : boolean;
  setOpen : (open : boolean) => void;
}

function CategoriesDropdown({
  open,
  setOpen,
} : CategoriesDropdownProps) {

  const {
    currentCategory,
    getRootCategories,
    getSubcategories,
    getCategorySlug,
    getCategoryImage,
    scrollToCategory,
  } = useCatalogue();

  const dropdown = useRef<HTMLDivElement>(null);
  const [category, setCategory] = useState(
    currentCategory()?.subcategoryIds.length ? currentCategory() : null
  );
  const [categories, setCategories] = useState<ListItem[]>([]);

  const handleClose = useCallback(() => setOpen(false), [setOpen]);
  const handleBackCategory = useCallback(() => { setCategory(null) }, []);

  const { contain, takeFocus, returnFocus, setLastActive } = useFocus({
    container : dropdown,
    onEscape : handleClose,
  });

  const handleCategoryClick = useCallback((cat : Category) => {
    if (!category && cat.subcategoryIds.length) {
      setCategory(cat);
      // TODO: scroll to top, set focus
      return;
    }
    handleClose();
    setLastActive(scrollToCategory(cat) ?? null);
  }, [category, handleClose, setLastActive, scrollToCategory]);

  useEffect(() => {
    if (category) {
      setCategories([
        {
          name : 'All Categories',
          onClick : handleBackCategory,
          icon : <ChevronLeftCircle />,
        },
        {
          name : category.name,
          href : `#${getCategorySlug(category)}`,
          image : getCategoryImage(category, 'card'),
          onClick : () => handleCategoryClick(category),
        },
        ...Object.values(getSubcategories(category)).map((subCategory) => ({
          name : subCategory.name,
          href : `#${getCategorySlug(subCategory)}`,
          image : getCategoryImage(subCategory, 'card'),
          onClick : () => handleCategoryClick(subCategory),
        })),
      ]);
      return;
    }

    setCategories(getRootCategories());
  }, [
    category,
    getRootCategories,
    getSubcategories,
    getCategorySlug,
    getCategoryImage,
    handleCategoryClick,
    handleBackCategory,
  ]);

  useEffect(() => {
    const current = currentCategory();
    setCategory(current?.subcategoryIds.length ? current : null)
  }, [open, currentCategory]);
  useEffect(() => { contain(); }, [categories, contain]);

  return (
    <>
      <CategoriesDropdownView bgColor='black' ref={dropdown} show={open}>
        <CategoriesList categories={categories} onClick={handleCategoryClick}/>
      </CategoriesDropdownView>
      <TransitionGroup component={null}>
        { open && (
          <Transition
            key="mobile-menu-overlay"
            classNames="overlay"
            timeout={250}
            onEntered={takeFocus}
            onExited={returnFocus}
          >
            <CategoriesDropdownOverlay
              onClick={handleClose}
            />
          </Transition>
        ) }
      </TransitionGroup>
    </>
  );
}

export default CategoriesDropdown;
