import React, { useEffect, useRef, useState } from 'react';
import { isBrowser } from 'react-device-detect';
import styled from '@emotion/styled';

import { Theme } from '#types';

import useConfig from '#hooks/useConfig';

import SkipLink from '#materials/SkipLink';

interface Style {
  theme : Theme;
}

interface HeaderViewStyle extends Style {
  height : string;
  stuck : boolean;
  borderColour? : string;
  maxWidth : string;
}

const HeaderContainer = styled.nav``

const HeaderView = styled.div<HeaderViewStyle>`
  position: fixed;
  z-index: 14;
  top: 0;
  right: 0;
  width: 100%;
  max-width: ${(props) => props.maxWidth};
  height: ${(props) => props.theme.layout.navHeight};
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.25s ease;
  background-color: ${(props) =>
    props.theme.header[props.stuck ? 'stuck' : 'primary']};
  box-shadow: ${(props) =>
    props.stuck ? props.theme.boxShadow.outer : 'none'};
  border: 0;
  border-bottom-width: 0.1rem;
  border-style: solid;
  border-color: ${(props) =>
    props.borderColour || props.theme.header[props.stuck ? 'stuck' : 'primary']};
  padding: 0 ${(props) => props.theme.layout.padding};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    height: ${(props) => props.theme.layout.navHeightMobile};
    padding: 0 ${(props) => props.theme.layout.paddingMobile};
  }
`

const HeaderTitle = styled.div<Style>`
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${(props) => props.theme.colours.light};

  > span {
    display: block;
    max-width: 26rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: ${(props) => props.theme.fonts.headings.family};
    font-weight: ${(props) => props.theme.fonts.headings.weight};
    letter-spacing: ${(props) => props.theme.fonts.headings.letterSpacing};
    text-transform: ${(props) => props.theme.fonts.headings.textTransform};
    -webkit-font-smoothing: ${(props) =>
      props.theme.fonts.headings.fontSmoothing};
    font-size: ${(props) => props.theme.fonts.sizes.big};
    color: ${(props) => props.theme.buttons.colours.header.colour};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      font-size: ${(props) => props.theme.fonts.sizes.main};
    }
  }
`

const HeaderNav = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: inherit;
`

interface HeaderProps {
  left? : JSX.Element;
  title? : string | JSX.Element;
  right? : JSX.Element;
  borderColour? : string;
  maxWidth? : string;
  style? : React.CSSProperties;
}

const Header = ({
  left,
  title,
  right,
  borderColour,
  maxWidth = '100%',
  style,
} : HeaderProps) => {
  const { theme } = useConfig();

  const header = useRef<HTMLElement>(null);

  const height = isBrowser
    ? theme.layout.navHeight
    : theme.layout.navHeightMobile
  const [stuck, setStuck] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      if (header.current) {
        setStuck(header.current.getBoundingClientRect().top < 0)
      }
    };

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', () => handleScroll)
    };
  }, [])

  return (
    <HeaderContainer
      ref={header}
      role="navigation"
      aria-label="Primary Navigation"
    >
      <HeaderView
        theme={theme}
        height={height}
        stuck={stuck}
        borderColour={borderColour}
        maxWidth={maxWidth}
        style={style}
      >
        <SkipLink />
        <HeaderNav>{ left }</HeaderNav>
        { title && (
          <HeaderTitle theme={theme}>
            <span>{ title }</span>
          </HeaderTitle>
        ) }
        <HeaderNav>{ right }</HeaderNav>
      </HeaderView>
    </HeaderContainer>
  )
}

export default Header
