import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';

import { Link } from '#mrktbox';
import { Category } from '#mrktbox/types';

import { Theme } from '#types';

import useCatalogue from '#hooks/useCatalogue';

import { remToPx } from '#utils/style';

const defaultImage = process.env.REACT_APP_DEFAULT_CATEGORY_IMAGE;

interface Style {
  theme? : Theme;
}

interface CategoryImageStyle extends Style {
  imageUrl : string;
}

const CategoryItemView = styled.div<Style>`
  width: calc(25% - 1.5rem);
  padding: 0;

  @media (max-width: ${(props) => props.theme.breakpoints.narrow}) {
    width: calc(50% - 1rem);
  }

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    width: 100%;
  }
`;

const CategoryImage = styled.div<CategoryImageStyle>`
  flex-shrink: 0;
  width: 7rem;
  height: 7rem;
  background-size: cover;
  transition: ${(props) => props.theme.links.transition};
  background-color: ${(props) => props.theme.bgColours.tertiary};
  border-radius: ${(props) => props.theme.border.radius};
  background-image: url(${(props) => props.imageUrl});
`;

const CategoryItemButton = styled(Link)<Style>`
  width: 100%;
  display: flex;
  gap: 2rem;
  overflow: hidden;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: ${(props) => props.theme.bgColours.light};
  border-radius: 6px;
  border: .1rem solid ${(props) => props.theme.border.colour};
  text-decoration: none;

  :hover {
    transform: scale(1.05);
  }
`;

const CategoryItemText = styled.span<Style>`
  flex-grow: 1;
  line-height: ${(props) => props.theme.fonts.body.lineHeight};
  text-align: left;
  flex-direction: column;
  align-items: flex-start;
  color: ${(props) => props.theme.fonts.greenmachineCollision.colour};
  font-family: ${(props) => props.theme.fonts.greenmachineCollision.family};
  letter-spacing: ${(props) => props.theme.letterSpacing.sm};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 0 2rem;
  }
`;

const IconImage = styled.div<Style>`
  flex-shrink: 0;
  height: 7rem;
  padding: 2rem;
  line-height: 0;
  background-size: cover;
  transition: ${(props) => props.theme.links.transition};
  background-color: ${(props) => props.theme.bgColours.tertiary};
  border-radius: ${(props) => props.theme.border.radiusSmall};
`

interface ItemProps {
  key? : string,
  category? : Category,
  rootCategory? : Category,
  name? : string,
  image? : string,
  icon? : React.ReactElement<{ size : number }>,
  href? : string,
  onClick? : () => void,
}
interface CategoryItemProps extends ItemProps {
  category : Category,
  name? : undefined,
  icon? : undefined,
}
interface CategoryImageProps extends ItemProps {
  category? : undefined,
  name? : string,
  image? : string,
}
interface CategoryIconProps extends ItemProps {
  category? : undefined,
  name : string,
  icon : React.ReactElement<{ size : number }>,
}

function CategoryItem(props : CategoryItemProps) : React.ReactElement;
function CategoryItem(props : CategoryImageProps) : React.ReactElement;
function CategoryItem(props : CategoryIconProps) : React.ReactElement;
function CategoryItem({
  category,
  rootCategory,
  name,
  icon,
  image,
  href,
  onClick,
} : ItemProps) {
  const { getCategorySlug, getCategoryImage } = useCatalogue();
  const [categoryImage, setCategoryImage] = useState<string>(
    image ? image : (category ? getCategoryImage(category) : '')
  );

  const handleClick = useCallback(
    (event : React.MouseEvent<HTMLAnchorElement>) => {
      if (onClick) onClick();
    },
    [onClick],
  );

  useEffect(() => {
    if (category) setCategoryImage(getCategoryImage(category))
  }, [category, getCategoryImage]);

  return (
    <CategoryItemView>
      <CategoryItemButton
        to={href ?? (category
          ? `/category/${getCategorySlug(rootCategory ?? category)}`
          : '#')}
        onClick={handleClick}
      >
        { icon
          ? (<IconImage>
            { React.cloneElement(icon, { size : remToPx('3rem') }) }
          </IconImage>)
          : (<CategoryImage imageUrl={categoryImage ?? defaultImage} />)
        }
        <CategoryItemText>
          { name ?? category?.name }
        </CategoryItemText>
      </CategoryItemButton>
    </CategoryItemView>
  );
}

export default CategoryItem;
