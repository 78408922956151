import styled from '@emotion/styled';

import { Theme } from '#types';

interface Style { theme? : Theme;}

const ModalView = styled('div')<Style>`
  position: relative;
  width: 48rem;
  max-width: 90%;
  overflow: hidden;
  background-color: ${(props) => props.theme.bgColours.light};
  border-radius: ${(props) => props.theme.border.radius};
  margin: ${(props) => props.theme.layout.padding} auto;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin: ${(props) => props.theme.layout.paddingMobile} auto;
  }
`;

export default ModalView;
