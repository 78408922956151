import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';

import { CreditCard, ProjectedOrder } from '#mrktbox/types';
import { useNavigation, useOrders } from '#mrktbox';
import { formats, formatCurrency, formatDateTime } from '#mrktbox/utils';

import { Theme } from '#types';

import useRequests from '#hooks/useRequests';

import Text from '#materials/Text';
import Preface from '#materials/Preface';
import { Refresh } from '#materials/icons';
import ButtonStyled from '#materials/ButtonStyled';
import IconButton from '#materials/IconButton';
import FormSection from '#materials/FormSection';

import Headline from '#components/page/Headline';
import CartSummaryItem from '#components/cart/CartSummaryItem';
import CheckSummary from '#components/checkout/CheckSummary';

import OrderSection from '#components/orders/OrderSection';
import OrderAddress from '#components/orders/OrderAddress';
import OrderRequestedAt from '#components/orders/OrderRequestedAt';
import OrderCards from '#components/orders/OrderCards';

interface Style { theme? : Theme; }

const OrderView = styled.div<Style>`
  margin: 4rem auto;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin: 3rem auto;
  }
`;

const OrderHeader = styled.div<Style>`
  text-align: center;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    text-align: left;
  }

  & > span {
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      font-size: ${(props) => props.theme.fonts.sizes.xSmall};
    }
  }
`;

const OrderTitle = styled(Headline)`
  margin: 0.5rem 0;
  font-size: ${(props) => props.theme.fonts.sizes.h1};
  font-family: ${(props) => props.theme.fonts.greenmachineCollision.family};
  letter-spacing: ${(props) => props.theme.letterSpacing.sm};
  color: ${(props) => props.theme.fonts.greenmachineCollision.colour};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: ${(props) => props.theme.fonts.sizes.h3};
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.fonts.sizes.h4};
  }
`;

const OrderButtons = styled.div<Style>`
  margin: 3rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    justify-content: flex-start;
    margin: 2.5rem 0 3rem;
  }

  button + button {
    margin-left: 1rem;
  }
`;

const SplitView = styled.div<Style>`
  display: flex;
  max-width: 100%;
  margin: auto;
  justify-content: center;
  flex-align: stretch;

  @media (max-width: ${(props) => props.theme.breakpoints.narrow}) {
    width: 54rem;
    flex-direction: column;
  }
`;

interface RecieptStyle extends Style { pad? : boolean; }

const Reciept = styled.div<RecieptStyle>`
  max-width: 54rem;
  flex: 1;
  padding: 0 ${(props) => props.pad ? '2rem' : '0'};

  @media (max-width: ${(props) => props.theme.breakpoints.narrow}) {
    padding: 0;
  }
`;

const Ruler = styled.div<Style>`
  display: block;
  width: 0;
  margin: 2rem 0 0;
  border-left: 1px solid ${(props) => props.theme.border.colour};

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    display: none;
  }
`;

const OrderDetails = styled.div`
  margin: 3rem 0 0;
`;

const OrderItems = styled.div`
  margin: 3rem 0 0;
`;

const TimeRemaining = styled(Text)<Style>`
  display: block;
  margin: ${(props) => props.theme.layout.paddingSmall} 0 0;
  font-family: ${(props) => props.theme.fonts.preface.family};
  font-weight: ${(props) => props.theme.fonts.preface.weight};
  letter-spacing: ${(props) => props.theme.fonts.preface.letterSpacing};
  line-height: ${(props) => props.theme.fonts.preface.lineHeight};
`;

const OrderStatusContainer = styled.div<Style>`
  display: flex;
  gap: 2rem;
  align-items: center;
  max-width: 54rem;
  margin: 2rem auto;
  padding: 0 1rem;
  // background-color: ${(props) => props.theme.buttons.colours.primary.bgColour};
  background-color: ${(props) => props.theme.fonts.greenmachineCollision.colour};
  border-radius: 10px;
`;

const OrderStatusLeft = styled.div<Style>`
  flex: 1 0 auto;
  width : 20px;
  margin: 0;
  padding: 1rem;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: none;
  }
`;

const OrderStatus = styled(Text)<Style>`
  flex: 1 0 auto;
  width : 20px;
  margin: 0;
  padding: 1rem;
  white-space: nowrap;
  text-align: center;
  background-color: ${(props) => props.theme.fonts.greenmachineCollision.colour};
  color: ${(props) => props.theme.colours.light};
`;

const OrderRefresh = styled.div<Style>`
  display: flex;
  width : 20px;
  margin: 0 0 0 auto;
  flex: 1 0 auto;
  justify-content: end;
`;

const CheckoutButtons = styled.div`
  margin: 3rem 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 auto;
`;

const ContactUs = styled.div<Style>`
  display: flex;
  flex-direction: column;
  margin: 4rem 0;
`;

function formatRemaining(remaining : number | null) {
  if (remaining === null || remaining < 0) return '';
  return remaining < 60000
    ? `${Math.ceil(remaining / 1000)}s Remaining`
    : (remaining < 3600000
      ? `${Math.ceil(remaining / 60000)}min Remaining`
      : ``);
}

function calculateRemaining(cutoff : Date | null) {
  return !!cutoff ? (cutoff.getTime() - new Date().getTime()) : null;
}

interface OrderProps {
  order: ProjectedOrder;
}

function Order({
  order,
} : OrderProps) {
  const { navigate } = useNavigation();
  const {
    waiting,
    isCurrentOrder,
    isOrderStocked,
    isOrderAvailable,
    isOrderOpen,
    calculateCutoff,
    getLineItems,
    editOrder,
    payOrder,
  } = useRequests();

  const { refreshOrder } = useOrders();

  const [cutoff, setCutoff] = useState(calculateCutoff(order));
  const [remaining, setRemaining] = useState(calculateRemaining(cutoff));
  const [items, setItems] = useState(getLineItems(order));
  const [card, setCard] = useState<CreditCard | null>(null);
  const [newCard, setNewCard] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [processing, setProcessing] = useState(false);

  const handleEdit = useCallback(() => {
    editOrder(order);
    navigate('/');
  }, [order, navigate, editOrder]);

  const handlePay = useCallback(async (token? : string) => {
    if (!token && !card) return false;
    setProcessing(true);
    const success = !!(await payOrder({
      order,
      ...(token ? { token } : (card && { card })),
    }));
    setProcessing(false);
    return success;
  }, [order, card, payOrder]);

  const handleRefresh = useCallback(async () => {
    if (!order.order?.id) return;
    setRefreshing(true);
    await refreshOrder(order.order.id)
    setRefreshing(false);
  }, [refreshOrder, order.order?.id])

  useEffect(() => {
    setCutoff(calculateCutoff(order));
  }, [order, calculateCutoff]);
  useEffect(() => { setItems(getLineItems(order)); }, [order, getLineItems]);

  useEffect(() => {
    const pastInterval = setInterval(
      () => setRemaining(calculateRemaining(cutoff)),
      1000,
    );
    return () => clearInterval(pastInterval);
  }, [cutoff]);

  useEffect(() => {
    const refreshInterval = setInterval(() => handleRefresh(), 120000);
    return () => clearInterval(refreshInterval);
  }, [order, handleRefresh]);

  const orderTitle = order.address
    ? 'Delivery Order'
    : (order.location
      ? `Pickup Order from ${order.location?.name}`
      : 'Draft Order');

  const canEdit = isOrderOpen(order);

  const total = order.totals.find((t) => t.key === 'total');
  const checkout = canEdit
    && order.complete
    && order.serviceChannel?.requireCheckout;

  const orderStatus = order.status === 'pending' || order.status === 'confirmed'
    ? 'Pending'
    : order.status === 'inProgress'
      ? 'In Progress'
      : (order.status === 'ready'
        ? 'Ready'
        : '');

  const showStatus = order.serviceChannel?.requireCheckout
    && order.paid
    && order.status !== 'fulfilled'
    && order.status !== 'cancelled';

  const available = isOrderAvailable(order);
  const stocked = isOrderStocked(order);
  const pastCutoff = !order.paid && (remaining !== null && remaining <= 0);
  const remainingText = formatRemaining(remaining);

  const invalid = !available || !stocked || pastCutoff;

  const buttonText = available
    ? 'Submit' + (total ? ` - ${formatCurrency(total?.total)}` : '')
    : 'Unavailable Items';

  return (
    <OrderView>
      <OrderHeader>
        <Preface size="small" color="tertiary">
          { order.order ? `Order #${order.order.id}` : 'Order Details' }
        </Preface>
        <OrderTitle as={'h1'}>{ orderTitle }</OrderTitle>
        { showStatus
          ? (
            <OrderStatusContainer>
              <OrderStatusLeft/>
              <OrderStatus size="big">
                { orderStatus }
              </OrderStatus>
              <OrderRefresh>
                <IconButton
                  onClick={handleRefresh}
                  disabled={refreshing}
                  filled
                  margin='0.5rem 0'
                >
                  <Refresh />
                </IconButton>
              </OrderRefresh>
            </OrderStatusContainer>
          ) : (order.status !== 'fulfilled' && (
            <>
              <Preface size="small" color="tertiary">
                { !pastCutoff
                  ? (cutoff
                    ? `Order Cutoff: ${formatDateTime(cutoff, formats.easy)}`
                    : '')
                  : 'Order Cutoff Passed'
                }
              </Preface>
              { (cutoff && !pastCutoff && remainingText) && (
                <TimeRemaining
                  size="small"
                  color="tertiary"
                  style={{}}
                >
                  ({ remainingText })
                </TimeRemaining>
              ) }
            </>
        )) }
        <OrderButtons>
          { canEdit && (
            <ButtonStyled
              size="small"
              onClick={handleEdit}
            >
              { isCurrentOrder(order) ? 'Continue Ordering' : 'Edit Order' }
            </ButtonStyled>
          ) }
        </OrderButtons>
      </OrderHeader>
      <SplitView>
        <Reciept pad={true}>
          <OrderDetails>
            { order.time && (
              <OrderSection label="Requested Time">
                <OrderRequestedAt time={order.time} />
              </OrderSection>
            ) }
            { order.address && (
              <OrderSection label="Delivery Address">
                <OrderAddress address={order.address} />
              </OrderSection>
            ) }
            { !order.address && order.location?.address && (
              <OrderSection label="Pickup Location">
                <OrderAddress address={order.location.address} />
              </OrderSection>
            ) }
          </OrderDetails>
          <FormSection title="Order Summary & Receipt">
            { items.length > 0 && (
              <OrderItems>
                { items.map((item, index) => {
                  return (
                    <CartSummaryItem
                      key={`${item.id}-${index}`}
                      order={order}
                      lineItem={item}
                    />
                  )
                })}
              </OrderItems>
            )}
            <CheckSummary order={order} showTenders={true} />
          </FormSection>
          { order.paid && (
            <ContactUs>
              <Preface>Need Help?</Preface>
              <span>
                email us at
                {' '}
                <a href="mailto:hello@batched.ca">
                  hello@batched.ca
                </a>
              </span>
            </ContactUs>
          ) }
        </Reciept>
        { checkout && (
          <>
            <Ruler />
            <Reciept pad>
              <OrderCards
                selected={card}
                total={total}
                newCard={newCard}
                setSelected={setCard}
                setNewCard={setNewCard}
                onSubmit={handlePay}
                disabled={processing || invalid || waiting}
              />
              { !newCard && (
                <CheckoutButtons>
                  <ButtonStyled
                    size="big"
                    disabled={!card || processing || invalid || waiting}
                    onClick={handlePay}
                    colour='green'
                  >
                    { buttonText }
                  </ButtonStyled>
                </CheckoutButtons>
              ) }
            </Reciept>
          </>
        ) }
      </SplitView>
    </OrderView>
  );
}

export default Order;
