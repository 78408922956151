import React, { useCallback } from 'react'
import styled from '@emotion/styled'

import { Link } from '#mrktbox'

import { Theme } from '#types';

import useConfig from '#hooks/useConfig'

interface ComponentProps {
  component : 'button' | 'a';
  href? : string;
  onClick? : (
    event : React.MouseEvent<HTMLButtonElement>
    | React.MouseEvent<HTMLAnchorElement>
  ) => void;
  fullwidth? : boolean;
  disableHover? : boolean;
  children : React.ReactNode;
  ref? : React.RefObject<HTMLAnchorElement>
  | React.RefObject<HTMLButtonElement>;
  id? : string;
}
interface LinkProps extends ComponentProps{
  component : 'a';
  href : string;
}
interface ButtonProps extends ComponentProps{
  component : 'button';
  href? : undefined;
}

function Component(props : LinkProps) : React.ReactElement;
function Component(props : ButtonProps) : React.ReactElement;
function Component(props : ComponentProps) : React.ReactElement;
function Component({
  component,
  href,
  onClick,
  children,
  ref,
  fullwidth,
  disableHover,
  ...props
} : ComponentProps) {
  switch (component) {
    case 'a':
      const aRef = ref as React.RefObject<HTMLAnchorElement>;
      return (<Link to={href ?? '/'} ref={aRef} onClick={onClick} {...props}>
        { children }
      </Link>);
    default:
      const btnRef = ref as React.RefObject<HTMLButtonElement>;
      return (
        <button onClick={onClick} ref={btnRef} {...props}>{ children }</button>
      );
  }
}

interface ButtonStyle {
  theme : Theme;
  disabled? : boolean;
  disableHover? : boolean;
  colour : 'header' | 'primary' | 'secondary' | 'light' | 'green' | 'link';
  size : keyof Theme['buttons']['sizes'];
  fullwidth : boolean;
}

interface ButtonIconStyle {
  isSmall : boolean;
}

const ButtonStyledView = styled(Component)<ButtonStyle>`
  cursor: pointer;
  display: flex;
  ${(props) => props.size === 'small'
    && 'height: calc((2'
      + ` * ${props.theme.buttons.sizes[props.size].padding.split(' ')[0]})`
      + ` + ${props.theme.buttons.sizes[props.size].fontSize} + 0.2rem);`}
  width: ${(props) => (props.fullwidth ? '100%' : 'fit-content')};
  align-items: center;
  text-decoration: ${(props) => (
    props.disableHover
      || props.theme.buttons.colours[props.colour].bgColour !== 'transparent'
      || props.theme.buttons.colours[props.colour].borderColour !== 'transparent'
  ) ? 'none' : 'underline'};
  justify-content: center;
  line-height: 1;
  text-align: center;
  margin: 0;
  opacity: ${(props) => (props.disabled ? '0.5' : '1.0')};
  transition: ${(props) => props.theme.links.transition};
  font-family: ${(props) => props.theme.buttons.sizes[props.size].family};
  font-weight: ${(props) => props.theme.buttons.sizes[props.size].weight};
  font-style: ${(props) => props.theme.buttons.sizes[props.size].fontStyle};
  -webkit-font-smoothing: ${(props) =>
    props.theme.buttons.sizes[props.size].fontSmoothing};
  letter-spacing: ${(props) =>
    props.theme.buttons.sizes[props.size].letterSpacing};
  text-transform: ${(props) =>
    props.theme.buttons.sizes[props.size].textTransform};
  font-size: ${(props) => props.theme.buttons.sizes[props.size].fontSize};
  padding: ${(props) => props.theme.buttons.sizes[props.size].padding};
  border-style: solid;
  border-width: ${(props) => props.theme.buttons.sizes[props.size].borderWidth};
  border-radius: ${(props) =>
    props.theme.buttons.sizes[props.size].borderRadius};
  color: ${(props) => props.theme.buttons.colours[props.colour].colour};
  background-color: ${(props) =>
    props.theme.buttons.colours[props.colour].bgColour};
  border-color: ${(props) =>
    props.theme.buttons.colours[props.colour].borderColour};

  &:visited, &:active, &:focus {
    color: ${(props) => props.theme.buttons.colours[props.colour].colour};
    background-color: ${(props) =>
      props.theme.buttons.colours[props.colour].bgColour};
    border-color: ${(props) =>
      props.theme.buttons.colours[props.colour].borderColour};
  }

  ${(props) => (props.disabled || props.disableHover)
    ? `pointer-events: none;`
    : `&:hover {
      color: ${props.theme.buttons.colours[`${props.colour}Hover`].colour};
      background-color: ${
        props.theme.buttons.colours[`${props.colour}Hover`].bgColour
      };
      border-color: ${
        props.theme.buttons.colours[`${props.colour}Hover`].borderColour
      };

      @media (max-width: ${props.theme.breakpoints.tablet}) {
        color: ${props.theme.buttons.colours[props.colour].colour};
        background-color: ${props.theme.buttons.colours[props.colour].bgColour};
        border-color: ${props.theme.buttons.colours[props.colour].borderColour};
      }
    }
  `}

  &:disabled {
    color: ${(props) => props.theme.buttons.colours[props.colour].colour};
    background-color: ${(props) =>
      props.theme.buttons.colours[props.colour].bgColour};
    border-color: ${(props) =>
      props.theme.buttons.colours[props.colour].borderColour};
  }

  & > span {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const ButtonStyledIcon = styled.span<ButtonIconStyle>`
  display: block;
  flex-shrink: 0;
  line-height: 0;
  width: ${(props) => (props.isSmall ? '1.2rem' : '1.4rem')};
  width: ${(props) => (props.isSmall ? '1.2rem' : '1.4rem')};
  margin-right: ${(props) => (props.isSmall ? '0.6rem' : '0.8rem')};
`

interface ButtonStyledProps {
  label? : string;
  href? : string;
  onClick? : () => void;
  disabled? : boolean;
  preventDefault? : boolean;
  icon? : string | React.ReactNode;
  type? : 'button' | 'submit';
  size? : keyof Theme['buttons']['sizes'];
  colour? : 'primary' | 'secondary' | 'light' | 'header' | 'green' | 'link';
  fullwidth? : boolean;
  btnRef? : React.RefObject<HTMLButtonElement>;
  id? : string;
  children : React.ReactNode;
}

const ButtonStyled = ({
  label,
  href,
  onClick,
  disabled,
  preventDefault,
  icon,
  type = 'button',
  size = 'default',
  colour = 'primary',
  fullwidth = false,
  btnRef,
  id,
  children,
} : ButtonStyledProps) => {
  const { theme } = useConfig();

  const handleClick = useCallback(
    (event : React.MouseEvent<HTMLButtonElement>
      | React.MouseEvent<HTMLAnchorElement>) => {
      event.stopPropagation()
      if (preventDefault) event.preventDefault()
      if (!disabled && onClick) onClick()
    },
    [disabled, preventDefault, onClick],
  )

  return (
    <ButtonStyledView
      component={href ? 'a' : 'button'}
      href={!disabled ? href : '#'}
      ref={btnRef}
      id={id}
      aria-label={label}
      onClick={type === 'submit' ? undefined : handleClick}
      disabled={disabled}
      disableHover={disabled || (!href && !onClick && type !== 'submit')}
      theme={theme}
      size={size}
      colour={colour}
      fullwidth={fullwidth}
    >
      <span>
        { icon && (
          <ButtonStyledIcon isSmall={['header', 'small'].includes(size)}>
            { icon }
          </ButtonStyledIcon>
        ) }
        {children}
      </span>
    </ButtonStyledView>
  );
}

export default ButtonStyled;
