import React, { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import { PaymentToken } from '#mrktbox/types';
import { useIntegrations, useCards } from '#mrktbox';
import { listRecords } from '#mrktbox/utils';

import useLogin from '#hooks/useLogin';

import Content from '#components/page/Content';
import CreditCardForm from '#components/creditCards/CreditCardForm';
import Main from '#components/page/Main';
import PageContainer from '#components/page/PageContainer';
import PageTitle from '#components/page/PageTitle';
import PageTitleButtons from '#components/page/PageTitleButtons';

import ButtonStyled from '#materials/ButtonStyled';

import CreditCardDetails from '#slices/CreditCardDetails';

function ProfilePage() {
  const { getIntegration } = useIntegrations();
  const { creditCards, createCreditCard } = useCards();
  const { customer } = useLogin();

  const [creatingNew, setCreatingNew] = useState(false);

  const handleAddCreditCard = useCallback(() => {
    setCreatingNew(true);
  }, [setCreatingNew]);

  const handleCancel = useCallback(() => {
    setCreatingNew(false);
  }, [setCreatingNew]);

  const handleSubmit = useCallback(async (token : PaymentToken) => {
    if (!customer || !customer.id) return false;
    const success = !!(await createCreditCard(
      {
        last4 : token.last4,
        brand : token.brand,
        expMonth : token.expMonth,
        expYear : token.expYear,
        customerId : customer.id,
      },
      customer,
      token.token,
      token.postal,
    ));
    if (success) setCreatingNew(false);
    return success;
  }, [customer, createCreditCard]);

  const integration = getIntegration({
    engineName : 'square',
    channelName : 'accounts',
  });
  const canCreate = (
    customer
    && customer.id
    && integration?.settings?.app_id
    && integration?.settings?.location_id
  )

  return (
    <>
      <Helmet>
        <title>{(`Your Credit Cards`)}</title>
      </Helmet>
      <Content>
        <Main bgColour='light'>
          <PageContainer style={{ maxWidth: '72rem' }}>
            { !creatingNew ?
              <>
                <PageTitle
                  title="Your Credit Cards"
                  subtitle={'A list of the credit cards you have on file '
                    + 'with us. Delete a card, or add a new one.'}
                />
                { !!listRecords(creditCards).length &&
                  <CreditCardDetails
                    creditCards={listRecords(creditCards)}
                  />
                }
                { canCreate && (
                  <PageTitleButtons>
                    <ButtonStyled
                      onClick={handleAddCreditCard}
                      colour='green'
                    >
                      Add a New Credit Card
                    </ButtonStyled>
                  </PageTitleButtons>
                ) }
              </>
              :
              <>
                <PageTitle
                  title="Add a New Credit Card"
                  subtitle="Input your credit card details and click submit."
                />
                <CreditCardForm
                  onSaved={handleSubmit}
                  onCancel={handleCancel}
                />
              </>
            }
          </PageContainer>
        </Main>
      </Content>
    </>
  );
}

export default ProfilePage;
