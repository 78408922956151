import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { isMobile } from 'react-device-detect';
import styled from '@emotion/styled';

import { useNavigation } from '#mrktbox';

import useConfig from '#hooks/useConfig';

import ScreenreaderTitle from '#materials/ScreenreaderTitle';

import HighlightedCatalogue from '#slices/HighlightedCatalogue';
import CategoriesList from '#slices/CategoriesList';

import Content from '#components/page/Content';
import Main from '#components/page/Main';
import PageHero from '#components/page/PageHero';

const HeroImage = process.env.REACT_APP_CATALOGUE_HERO;
const HeroImageMobile = process.env.REACT_APP_CATALOGUE_HERO_MOBILE;

const MenuNewView = styled.div``;

const MenuAnnouncements = styled.div``;

const Catalogue = () => {
  const { brand } = useConfig();
  const { navigate } = useNavigation();

  const heroRef = useRef<HTMLDivElement>(null);

  const heroHeight = isMobile ? '24rem' : '36rem'
  const img = isMobile ? HeroImageMobile : HeroImage

  useEffect(() => {
    if (window.location.pathname !== '/') {
      navigate('/');
    }
  }, [navigate]);

  return (
    <>
      <Helmet>
        <title>Menu | {brand.title}</title>
      </Helmet>
      <Content>
        <Main bgColour='black'>
          <ScreenreaderTitle>Menu</ScreenreaderTitle>
          <MenuNewView>
            <MenuAnnouncements ref={heroRef}>
              <PageHero
                height={heroHeight}
                imageUrl={img}
              />
            </MenuAnnouncements>
            <HighlightedCatalogue />
            <div id='categories'>
              <CategoriesList />
            </div>
          </MenuNewView>
        </Main>
      </Content>
    </>
  );
}

export default Catalogue;
